import {
  getProduct,
  patchProduct,
  getProducts,
  getProductsLight,
  getCategories,
  getProductsLightQuery,
  getProductEntity,
  getProductAttributes,
  getProductsQuery,
  getProductsForCustomer,
  getProductAttributesQuery,
  getProductsLightWithDeleted,
  getProductQuery,
  getProductCategories,
} from "api/products/calls";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";
import { createAsyncUpdateConnector } from "utilities";

export const useProduct = createPrimitiveHook(getProduct);
export const useProducts = createPrimitivePaginatedHook(getProducts);
export const useProductsLight = createPrimitivePaginatedHook(getProductsLight);
export const useProductsLightWithDeleted = createPrimitivePaginatedHook(
  getProductsLightWithDeleted,
);
export const useProductCategories = createPaginatedApiQuery(getProductCategories);
export const useClientProducts = createPrimitivePaginatedHook(getProductsForCustomer);
export const useProductQuery = createApiQuery(getProductQuery);
export const useProductsLightQuery = createPaginatedApiQuery(getProductsLightQuery);
export const useProductAttributes = createApiQuery(getProductAttributes);
export const useProductsQuery = createPaginatedApiQuery(getProductsQuery);
export const useProductAttribute = createPaginatedApiQuery(getProductAttributesQuery);

export const useUpdateProduct = createAsyncUpdateConnector(patchProduct);
export const useCategories = createPrimitivePaginatedHook(getCategories);
export const useProductEntity = createPrimitiveHook(getProductEntity);
