import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { OrderPackageExchange } from "api/order-packages-exchange/models";
import { packageColumnFactory } from "../shared/packageColumnFactory";
import { SourceOrderPackageStatus } from "api/order-packages-exchange/enums";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";
import { useParams } from "react-router";
import { NormalizePackagesListItem } from "../shared/ProductListItem";
import { assertIsDefined } from "utilities/assertIsDefined";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";

export const useSourceColumns = () => {
  return useCreateTableColumns<NormalizePackagesListItem>(({ columnHelper }) => {
    const columns = packageColumnFactory(columnHelper);
    return [
      columns.packageName(240),
      columns.sku(),
      columns.location(),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 100,
        cell: info => {
          const row = info.getValue();
          if (!row.isAvailable) return null;
          assertIsDefined(row.sourceStatus);
          return getSourceStatusTag(row.sourceStatus);
        },
      }),
      columnHelper.stretchContent,
      columnHelper.accessor(row => row, {
        header: " ",
        id: "link",
        size: 34,
        cell: info => {
          const row = info.getValue();
          if (!row.isAvailable) return null;
          return <RowLinkActionButton row={row} />;
        },
      }),
    ];
  });
};

const RowLinkActionButton = ({ row }: { row: NormalizePackagesListItem }) => {
  const { id } = useParams<{ id: OrderPackageExchange["id"] }>();
  const postLinkPackagesMutation = orderPackageExchangeActions.usePostLinkPackages();

  return (
    <IconButton
      isLoading={postLinkPackagesMutation.isLoading}
      disabled={
        row.sourceStatus === SourceOrderPackageStatus.LINKED ||
        row.sourceStatus === SourceOrderPackageStatus.CONFIRMED ||
        row.sourceStatus === SourceOrderPackageStatus.RELEASED
      }
      icon={MdiArrowForward}
      variant="transparent"
      onClick={() => {
        postLinkPackagesMutation.mutate({
          packagesUniqueCodes: [row.uniqueCode],
          orderPackagesExchangeId: id,
        });
      }}
    />
  );
};

function getSourceStatusTag(status: NonNullable<NormalizePackagesListItem["sourceStatus"]>) {
  switch (status) {
    case SourceOrderPackageStatus.CONFIRMED:
      return <Tag label="Wymieniono" variant="success" type="outlined" />;

    case SourceOrderPackageStatus.LINKED:
      return <Tag label="Przypisano" variant="info" type="outlined" />;

    case SourceOrderPackageStatus.WAITING_FOR_LINK:
      return <Tag label="Dostępna" variant="deepPurple50" type="outlined" />;

    case SourceOrderPackageStatus.RELEASED:
      return <Tag label="Wydano" variant="quaternary" type="outlined" />;

    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled status: ${exhaustiveCheck}`);
      return <EmptyValue />;
  }
}
