import { CURRENCY_TYPE, countryCurrenciesFlags } from "CONSTANTS";
import { carrierOrdersActions } from "api/logistics/carrierOrders/actions";
import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay/AmountDisplay";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { RightPanelSection } from "components/utils/drawer";
import { cx, dictToList } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  carrierOrder: CarrierOrder;
}

export const RouteCostSection = ({ carrierOrder }: Props) => {
  const currenciesOptions = dictToList(countryCurrenciesFlags).map(({ key, value }) => ({
    icon: value,
    text: key,
    type: MenuItemType.ICON,
    value: key,
  }));

  return (
    <RightPanelSection padding="px-3 pt-2 pb-3" title="Kwota za trasę">
      <div className="d-flex justify-content-start align-items-center gap-2">
        <div className="d-flex align-items-baseline gap-2">
          <div className="d-flex align-items-baseline gap-2 mr-3">
            <Typography fontSize="14" fontWeight="600" noWrap>
              Kwota z samochodu:
            </Typography>
            {carrierOrder.routeCost.carRouteCost.amount !== null ? (
              <AmountDisplay
                className={cx({
                  "line-through": carrierOrder.routeCost.customRouteCost.amount !== null,
                })}
                amount={String(carrierOrder.routeCost.carRouteCost.amount)}
                currency={carrierOrder.routeCost.carRouteCost.currency}
                integerTypographyProps={{
                  fontSize: "16",
                  fontWeight: "600",
                }}
                decimalTypographyProps={{
                  fontSize: "14",
                  fontWeight: "500",
                }}
              />
            ) : (
              <EmptyValue fontSize="14" />
            )}
          </div>
          <TextField.Async
            label="kwota za trasę"
            mutationHook={() => carrierOrdersActions.usePatchCarrierOrderRouteCost(carrierOrder)}
            transformQueryData={customRouteCost => ({
              id: Number(carrierOrder.route.id),
              toUpdate: {
                customRouteCost,
              },
            })}
            size="small"
            type="number"
            value={carrierOrder.routeCost.customRouteCost.amount ?? ""}
          />
        </div>
        <Select.Async
          items={currenciesOptions}
          label="Waluta"
          mutationHook={() => carrierOrdersActions.usePatchCarrierOrderRouteCost(carrierOrder)}
          selected={carrierOrder.routeCost.customRouteCost.currency}
          transformQueryData={customRouteCostCurrency => ({
            id: Number(carrierOrder.route.id),
            toUpdate: {
              customRouteCostCurrency: customRouteCostCurrency as CURRENCY_TYPE,
            },
          })}
        />
      </div>
    </RightPanelSection>
  );
};
