// Auto-generated file - DO NOT EDIT MANUALLY

import pA1 from "./pA1.png";
import pA1D from "./pA1D.png";
import pA1P from "./pA1P.png";
import pA1S from "./pA1S.png";
import pA1T from "./pA1T.png";
import pA2 from "./pA2.png";
import pA2D from "./pA2D.png";
import pA2P from "./pA2P.png";
import pA2S from "./pA2S.png";
import pA2T from "./pA2T.png";
import pA3 from "./pA3.png";
import pA3D from "./pA3D.png";
import pA3P from "./pA3P.png";
import pA3S from "./pA3S.png";
import pA3T from "./pA3T.png";
import pA4 from "./pA4.png";
import pA4D from "./pA4D.png";
import pA4P from "./pA4P.png";
import pA4S from "./pA4S.png";
import pA4T from "./pA4T.png";
import pA5 from "./pA5.png";
import pA5D from "./pA5D.png";
import pA5P from "./pA5P.png";
import pA5S from "./pA5S.png";
import pA5T from "./pA5T.png";
import pA6 from "./pA6.png";
import pA6D from "./pA6D.png";
import pA6P from "./pA6P.png";
import pA6S from "./pA6S.png";
import pA6T from "./pA6T.png";
import pA7 from "./pA7.png";
import pA7D from "./pA7D.png";
import pA7P from "./pA7P.png";
import pA7S from "./pA7S.png";
import pA7T from "./pA7T.png";
import pA8 from "./pA8.png";
import pA8D from "./pA8D.png";
import pA8P from "./pA8P.png";
import pA8S from "./pA8S.png";
import pA8T from "./pA8T.png";
import pB1 from "./pB1.png";
import pB1D from "./pB1D.png";
import pB1P from "./pB1P.png";
import pB1S from "./pB1S.png";
import pB1T from "./pB1T.png";
import pB2 from "./pB2.png";
import pB2D from "./pB2D.png";
import pB2P from "./pB2P.png";
import pB2S from "./pB2S.png";
import pB2T from "./pB2T.png";
import pB3 from "./pB3.png";
import pB3D from "./pB3D.png";
import pB3P from "./pB3P.png";
import pB3S from "./pB3S.png";
import pB3T from "./pB3T.png";
import pB4 from "./pB4.png";
import pB4D from "./pB4D.png";
import pB4P from "./pB4P.png";
import pB4S from "./pB4S.png";
import pB4T from "./pB4T.png";
import pB5 from "./pB5.png";
import pB5D from "./pB5D.png";
import pB5P from "./pB5P.png";
import pB5S from "./pB5S.png";
import pB5T from "./pB5T.png";
import pB6 from "./pB6.png";
import pB6D from "./pB6D.png";
import pB6P from "./pB6P.png";
import pB6S from "./pB6S.png";
import pB6T from "./pB6T.png";
import pB7 from "./pB7.png";
import pB7D from "./pB7D.png";
import pB7P from "./pB7P.png";
import pB7S from "./pB7S.png";
import pB7T from "./pB7T.png";
import pB8 from "./pB8.png";
import pB8D from "./pB8D.png";
import pB8P from "./pB8P.png";
import pB8S from "./pB8S.png";
import pB8T from "./pB8T.png";
import pF1 from "./pF1.png";
import pF1D from "./pF1D.png";
import pF1P from "./pF1P.png";
import pF1S from "./pF1S.png";
import pF1T from "./pF1T.png";
import pF2 from "./pF2.png";
import pF2D from "./pF2D.png";
import pF2P from "./pF2P.png";
import pF2S from "./pF2S.png";
import pF2T from "./pF2T.png";
import pF3 from "./pF3.png";
import pF3D from "./pF3D.png";
import pF3P from "./pF3P.png";
import pF3S from "./pF3S.png";
import pF3T from "./pF3T.png";
import pF4 from "./pF4.png";
import pF4D from "./pF4D.png";
import pF4P from "./pF4P.png";
import pF4S from "./pF4S.png";
import pF4T from "./pF4T.png";
import pF5 from "./pF5.png";
import pF5D from "./pF5D.png";
import pF5P from "./pF5P.png";
import pF5S from "./pF5S.png";
import pF5T from "./pF5T.png";
import pF6 from "./pF6.png";
import pF6P from "./pF6P.png";
import pF6S from "./pF6S.png";
import pF6T from "./pF6T.png";
import pF7 from "./pF7.png";
import pF7D from "./pF7D.png";
import pF7P from "./pF7P.png";
import pF7S from "./pF7S.png";
import pF7T from "./pF7T.png";
import pF8 from "./pF8.png";
import pF8D from "./pF8D.png";
import pF8P from "./pF8P.png";
import pF8S from "./pF8S.png";
import pF8T from "./pF8T.png";
import pFD from "./pFD.png";
import pM1 from "./pM1.png";
import pM1D from "./pM1D.png";
import pM1P from "./pM1P.png";
import pM1S from "./pM1S.png";
import pM1T from "./pM1T.png";
import pM2 from "./pM2.png";
import pM2D from "./pM2D.png";
import pM2P from "./pM2P.png";
import pM2S from "./pM2S.png";
import pM2T from "./pM2T.png";
import pM3 from "./pM3.png";
import pM3D from "./pM3D.png";
import pM3P from "./pM3P.png";
import pM3S from "./pM3S.png";
import pM3T from "./pM3T.png";
import pM4 from "./pM4.png";
import pM4D from "./pM4D.png";
import pM4P from "./pM4P.png";
import pM4S from "./pM4S.png";
import pM4T from "./pM4T.png";
import pM5 from "./pM5.png";
import pM5D from "./pM5D.png";
import pM5P from "./pM5P.png";
import pM5S from "./pM5S.png";
import pM5T from "./pM5T.png";
import pM6 from "./pM6.png";
import pM6D from "./pM6D.png";
import pM6P from "./pM6P.png";
import pM6S from "./pM6S.png";
import pM6T from "./pM6T.png";
import pM7 from "./pM7.png";
import pM7D from "./pM7D.png";
import pM7P from "./pM7P.png";
import pM7S from "./pM7S.png";
import pM7T from "./pM7T.png";
import pM8 from "./pM8.png";
import pM8D from "./pM8D.png";
import pM8P from "./pM8P.png";
import pM8S from "./pM8S.png";
import pM8T from "./pM8T.png";
import pR1 from "./pR1.png";
import pR1D from "./pR1D.png";
import pR1P from "./pR1P.png";
import pR1S from "./pR1S.png";
import pR1T from "./pR1T.png";
import pR2 from "./pR2.png";
import pR2D from "./pR2D.png";
import pR2P from "./pR2P.png";
import pR2S from "./pR2S.png";
import pR2T from "./pR2T.png";
import pR3 from "./pR3.png";
import pR3D from "./pR3D.png";
import pR3P from "./pR3P.png";
import pR3S from "./pR3S.png";
import pR3T from "./pR3T.png";
import pR4 from "./pR4.png";
import pR4D from "./pR4D.png";
import pR4P from "./pR4P.png";
import pR4S from "./pR4S.png";
import pR4T from "./pR4T.png";
import pR5 from "./pR5.png";
import pR5D from "./pR5D.png";
import pR5P from "./pR5P.png";
import pR5S from "./pR5S.png";
import pR5T from "./pR5T.png";
import pR6 from "./pR6.png";
import pR6D from "./pR6D.png";
import pR6P from "./pR6P.png";
import pR6S from "./pR6S.png";
import pR6T from "./pR6T.png";
import pR7 from "./pR7.png";
import pR7D from "./pR7D.png";
import pR7P from "./pR7P.png";
import pR7S from "./pR7S.png";
import pR7T from "./pR7T.png";
import pR8 from "./pR8.png";
import pR8D from "./pR8D.png";
import pR8P from "./pR8P.png";
import pR8S from "./pR8S.png";
import pR8T from "./pR8T.png";
import pS1 from "./pS1.png";
import pS1D from "./pS1D.png";
import pS1P from "./pS1P.png";
import pS1S from "./pS1S.png";
import pS1T from "./pS1T.png";
import pS2 from "./pS2.png";
import pS2D from "./pS2D.png";
import pS2P from "./pS2P.png";
import pS2S from "./pS2S.png";
import pS2T from "./pS2T.png";
import pS3 from "./pS3.png";
import pS3D from "./pS3D.png";
import pS3P from "./pS3P.png";
import pS3S from "./pS3S.png";
import pS3T from "./pS3T.png";
import pS4 from "./pS4.png";
import pS4D from "./pS4D.png";
import pS4P from "./pS4P.png";
import pS4S from "./pS4S.png";
import pS4T from "./pS4T.png";
import pS5 from "./pS5.png";
import pS5D from "./pS5D.png";
import pS5P from "./pS5P.png";
import pS5S from "./pS5S.png";
import pS5T from "./pS5T.png";
import pS6 from "./pS6.png";
import pS6D from "./pS6D.png";
import pS6P from "./pS6P.png";
import pS6S from "./pS6S.png";
import pS6T from "./pS6T.png";
import pS7 from "./pS7.png";
import pS7D from "./pS7D.png";
import pS7P from "./pS7P.png";
import pS7S from "./pS7S.png";
import pS7T from "./pS7T.png";
import pS8 from "./pS8.png";
import pS8D from "./pS8D.png";
import pS8P from "./pS8P.png";
import pS8S from "./pS8S.png";
import pS8T from "./pS8T.png";
import pU1 from "./pU1.png";
import pU1D from "./pU1D.png";
import pU1P from "./pU1P.png";
import pU1S from "./pU1S.png";
import pU1T from "./pU1T.png";
import pU2 from "./pU2.png";
import pU2D from "./pU2D.png";
import pU2P from "./pU2P.png";
import pU2S from "./pU2S.png";
import pU2T from "./pU2T.png";
import pU3 from "./pU3.png";
import pU3D from "./pU3D.png";
import pU3P from "./pU3P.png";
import pU3S from "./pU3S.png";
import pU3T from "./pU3T.png";
import pU4 from "./pU4.png";
import pU4D from "./pU4D.png";
import pU4P from "./pU4P.png";
import pU4S from "./pU4S.png";
import pU4T from "./pU4T.png";
import pU5 from "./pU5.png";
import pU5D from "./pU5D.png";
import pU5P from "./pU5P.png";
import pU5S from "./pU5S.png";
import pU5T from "./pU5T.png";
import pU6 from "./pU6.png";
import pU6D from "./pU6D.png";
import pU6P from "./pU6P.png";
import pU6S from "./pU6S.png";
import pU6T from "./pU6T.png";
import pU7 from "./pU7.png";
import pU7D from "./pU7D.png";
import pU7P from "./pU7P.png";
import pU7S from "./pU7S.png";
import pU7T from "./pU7T.png";
import pU8 from "./pU8.png";
import pU8D from "./pU8D.png";
import pU8P from "./pU8P.png";
import pU8S from "./pU8S.png";
import pU8T from "./pU8T.png";
import pUF1 from "./pUF1.png";
import pUF1D from "./pUF1D.png";
import pUF1P from "./pUF1P.png";
import pUF1S from "./pUF1S.png";
import pUF1T from "./pUF1T.png";
import pUF2 from "./pUF2.png";
import pUF2D from "./pUF2D.png";
import pUF2P from "./pUF2P.png";
import pUF2T from "./pUF2T.png";
import pUF3 from "./pUF3.png";
import pUF3D from "./pUF3D.png";
import pUF3P from "./pUF3P.png";
import pUF3S from "./pUF3S.png";
import pUF3T from "./pUF3T.png";
import pUF4 from "./pUF4.png";
import pUF4D from "./pUF4D.png";
import pUF4P from "./pUF4P.png";
import pUF4S from "./pUF4S.png";
import pUF4T from "./pUF4T.png";
import pUF5 from "./pUF5.png";
import pUF5D from "./pUF5D.png";
import pUF5P from "./pUF5P.png";
import pUF5S from "./pUF5S.png";
import pUF5T from "./pUF5T.png";
import pUF6 from "./pUF6.png";
import pUF6D from "./pUF6D.png";
import pUF6P from "./pUF6P.png";
import pUF6S from "./pUF6S.png";
import pUF6T from "./pUF6T.png";
import pUF7 from "./pUF7.png";
import pUF7D from "./pUF7D.png";
import pUF7P from "./pUF7P.png";
import pUF7S from "./pUF7S.png";
import pUF7T from "./pUF7T.png";
import pUF8 from "./pUF8.png";
import pUF8D from "./pUF8D.png";
import pUF8P from "./pUF8P.png";
import pUF8S from "./pUF8S.png";
import pUF8T from "./pUF8T.png";
import pUFS from "./pUFS.png";
import pUR1 from "./pUR1.png";
import pUR1D from "./pUR1D.png";
import pUR1P from "./pUR1P.png";
import pUR1S from "./pUR1S.png";
import pUR1T from "./pUR1T.png";
import pUR2 from "./pUR2.png";
import pUR2D from "./pUR2D.png";
import pUR2P from "./pUR2P.png";
import pUR2S from "./pUR2S.png";
import pUR2T from "./pUR2T.png";
import pUR3 from "./pUR3.png";
import pUR3D from "./pUR3D.png";
import pUR3P from "./pUR3P.png";
import pUR3S from "./pUR3S.png";
import pUR3T from "./pUR3T.png";
import pUR4 from "./pUR4.png";
import pUR4D from "./pUR4D.png";
import pUR4P from "./pUR4P.png";
import pUR4S from "./pUR4S.png";
import pUR4T from "./pUR4T.png";
import pUR5 from "./pUR5.png";
import pUR5D from "./pUR5D.png";
import pUR5P from "./pUR5P.png";
import pUR5S from "./pUR5S.png";
import pUR5T from "./pUR5T.png";
import pUR6 from "./pUR6.png";
import pUR6D from "./pUR6D.png";
import pUR6P from "./pUR6P.png";
import pUR6S from "./pUR6S.png";
import pUR6T from "./pUR6T.png";
import pUR7 from "./pUR7.png";
import pUR7D from "./pUR7D.png";
import pUR7P from "./pUR7P.png";
import pUR7S from "./pUR7S.png";
import pUR7T from "./pUR7T.png";
import pUR8 from "./pUR8.png";
import pUR8D from "./pUR8D.png";
import pUR8P from "./pUR8P.png";
import pUR8S from "./pUR8S.png";
import pUR8T from "./pUR8T.png";
import pUS1 from "./pUS1.png";
import pUS1D from "./pUS1D.png";
import pUS1P from "./pUS1P.png";
import pUS1S from "./pUS1S.png";
import pUS1T from "./pUS1T.png";
import pUS2 from "./pUS2.png";
import pUS2D from "./pUS2D.png";
import pUS2P from "./pUS2P.png";
import pUS2S from "./pUS2S.png";
import pUS2T from "./pUS2T.png";
import pUS3 from "./pUS3.png";
import pUS3D from "./pUS3D.png";
import pUS3P from "./pUS3P.png";
import pUS3S from "./pUS3S.png";
import pUS3T from "./pUS3T.png";
import pUS4 from "./pUS4.png";
import pUS4D from "./pUS4D.png";
import pUS4P from "./pUS4P.png";
import pUS4S from "./pUS4S.png";
import pUS4T from "./pUS4T.png";
import pUS5 from "./pUS5.png";
import pUS5D from "./pUS5D.png";
import pUS5P from "./pUS5P.png";
import pUS5S from "./pUS5S.png";
import pUS5T from "./pUS5T.png";
import pUS6 from "./pUS6.png";
import pUS6D from "./pUS6D.png";
import pUS6P from "./pUS6P.png";
import pUS6S from "./pUS6S.png";
import pUS6T from "./pUS6T.png";
import pUS7 from "./pUS7.png";
import pUS7D from "./pUS7D.png";
import pUS7P from "./pUS7P.png";
import pUS7S from "./pUS7S.png";
import pUS7T from "./pUS7T.png";
import pUS8 from "./pUS8.png";
import pUS8D from "./pUS8D.png";
import pUS8P from "./pUS8P.png";
import pUS8S from "./pUS8S.png";
import pUS8T from "./pUS8T.png";
import pinned from "./pinned.png";
import pinnedD from "./pinnedD.png";
import pinnedP from "./pinnedP.png";
import pinnedS from "./pinnedS.png";
import pinnedT from "./pinnedT.png";

export const mapIcons = {
  pA1,
  pA1D,
  pA1P,
  pA1S,
  pA1T,
  pA2,
  pA2D,
  pA2P,
  pA2S,
  pA2T,
  pA3,
  pA3D,
  pA3P,
  pA3S,
  pA3T,
  pA4,
  pA4D,
  pA4P,
  pA4S,
  pA4T,
  pA5,
  pA5D,
  pA5P,
  pA5S,
  pA5T,
  pA6,
  pA6D,
  pA6P,
  pA6S,
  pA6T,
  pA7,
  pA7D,
  pA7P,
  pA7S,
  pA7T,
  pA8,
  pA8D,
  pA8P,
  pA8S,
  pA8T,
  pB1,
  pB1D,
  pB1P,
  pB1S,
  pB1T,
  pB2,
  pB2D,
  pB2P,
  pB2S,
  pB2T,
  pB3,
  pB3D,
  pB3P,
  pB3S,
  pB3T,
  pB4,
  pB4D,
  pB4P,
  pB4S,
  pB4T,
  pB5,
  pB5D,
  pB5P,
  pB5S,
  pB5T,
  pB6,
  pB6D,
  pB6P,
  pB6S,
  pB6T,
  pB7,
  pB7D,
  pB7P,
  pB7S,
  pB7T,
  pB8,
  pB8D,
  pB8P,
  pB8S,
  pB8T,
  pF1,
  pF1D,
  pF1P,
  pF1S,
  pF1T,
  pF2,
  pF2D,
  pF2P,
  pF2S,
  pF2T,
  pF3,
  pF3D,
  pF3P,
  pF3S,
  pF3T,
  pF4,
  pF4D,
  pF4P,
  pF4S,
  pF4T,
  pF5,
  pF5D,
  pF5P,
  pF5S,
  pF5T,
  pF6,
  pF6P,
  pF6S,
  pF6T,
  pF7,
  pF7D,
  pF7P,
  pF7S,
  pF7T,
  pF8,
  pF8D,
  pF8P,
  pF8S,
  pF8T,
  pFD,
  pM1,
  pM1D,
  pM1P,
  pM1S,
  pM1T,
  pM2,
  pM2D,
  pM2P,
  pM2S,
  pM2T,
  pM3,
  pM3D,
  pM3P,
  pM3S,
  pM3T,
  pM4,
  pM4D,
  pM4P,
  pM4S,
  pM4T,
  pM5,
  pM5D,
  pM5P,
  pM5S,
  pM5T,
  pM6,
  pM6D,
  pM6P,
  pM6S,
  pM6T,
  pM7,
  pM7D,
  pM7P,
  pM7S,
  pM7T,
  pM8,
  pM8D,
  pM8P,
  pM8S,
  pM8T,
  pR1,
  pR1D,
  pR1P,
  pR1S,
  pR1T,
  pR2,
  pR2D,
  pR2P,
  pR2S,
  pR2T,
  pR3,
  pR3D,
  pR3P,
  pR3S,
  pR3T,
  pR4,
  pR4D,
  pR4P,
  pR4S,
  pR4T,
  pR5,
  pR5D,
  pR5P,
  pR5S,
  pR5T,
  pR6,
  pR6D,
  pR6P,
  pR6S,
  pR6T,
  pR7,
  pR7D,
  pR7P,
  pR7S,
  pR7T,
  pR8,
  pR8D,
  pR8P,
  pR8S,
  pR8T,
  pS1,
  pS1D,
  pS1P,
  pS1S,
  pS1T,
  pS2,
  pS2D,
  pS2P,
  pS2S,
  pS2T,
  pS3,
  pS3D,
  pS3P,
  pS3S,
  pS3T,
  pS4,
  pS4D,
  pS4P,
  pS4S,
  pS4T,
  pS5,
  pS5D,
  pS5P,
  pS5S,
  pS5T,
  pS6,
  pS6D,
  pS6P,
  pS6S,
  pS6T,
  pS7,
  pS7D,
  pS7P,
  pS7S,
  pS7T,
  pS8,
  pS8D,
  pS8P,
  pS8S,
  pS8T,
  pU1,
  pU1D,
  pU1P,
  pU1S,
  pU1T,
  pU2,
  pU2D,
  pU2P,
  pU2S,
  pU2T,
  pU3,
  pU3D,
  pU3P,
  pU3S,
  pU3T,
  pU4,
  pU4D,
  pU4P,
  pU4S,
  pU4T,
  pU5,
  pU5D,
  pU5P,
  pU5S,
  pU5T,
  pU6,
  pU6D,
  pU6P,
  pU6S,
  pU6T,
  pU7,
  pU7D,
  pU7P,
  pU7S,
  pU7T,
  pU8,
  pU8D,
  pU8P,
  pU8S,
  pU8T,
  pUF1,
  pUF1D,
  pUF1P,
  pUF1S,
  pUF1T,
  pUF2,
  pUF2D,
  pUF2P,
  pUF2T,
  pUF3,
  pUF3D,
  pUF3P,
  pUF3S,
  pUF3T,
  pUF4,
  pUF4D,
  pUF4P,
  pUF4S,
  pUF4T,
  pUF5,
  pUF5D,
  pUF5P,
  pUF5S,
  pUF5T,
  pUF6,
  pUF6D,
  pUF6P,
  pUF6S,
  pUF6T,
  pUF7,
  pUF7D,
  pUF7P,
  pUF7S,
  pUF7T,
  pUF8,
  pUF8D,
  pUF8P,
  pUF8S,
  pUF8T,
  pUFS,
  pUR1,
  pUR1D,
  pUR1P,
  pUR1S,
  pUR1T,
  pUR2,
  pUR2D,
  pUR2P,
  pUR2S,
  pUR2T,
  pUR3,
  pUR3D,
  pUR3P,
  pUR3S,
  pUR3T,
  pUR4,
  pUR4D,
  pUR4P,
  pUR4S,
  pUR4T,
  pUR5,
  pUR5D,
  pUR5P,
  pUR5S,
  pUR5T,
  pUR6,
  pUR6D,
  pUR6P,
  pUR6S,
  pUR6T,
  pUR7,
  pUR7D,
  pUR7P,
  pUR7S,
  pUR7T,
  pUR8,
  pUR8D,
  pUR8P,
  pUR8S,
  pUR8T,
  pUS1,
  pUS1D,
  pUS1P,
  pUS1S,
  pUS1T,
  pUS2,
  pUS2D,
  pUS2P,
  pUS2S,
  pUS2T,
  pUS3,
  pUS3D,
  pUS3P,
  pUS3S,
  pUS3T,
  pUS4,
  pUS4D,
  pUS4P,
  pUS4S,
  pUS4T,
  pUS5,
  pUS5D,
  pUS5P,
  pUS5S,
  pUS5T,
  pUS6,
  pUS6D,
  pUS6P,
  pUS6S,
  pUS6T,
  pUS7,
  pUS7D,
  pUS7P,
  pUS7S,
  pUS7T,
  pUS8,
  pUS8D,
  pUS8P,
  pUS8S,
  pUS8T,
  pinned,
  pinnedD,
  pinnedP,
  pinnedS,
  pinnedT,
};
