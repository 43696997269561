import { UnloadingOrder } from "api/wms/unloading/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Unloading } from "api/wms/models";
import { ConfirmOrder } from "./ConfirmOrder";

export const useUnloadingOrdersColumns = (unloading: Unloading) => {
  return useCreateTableColumns<UnloadingOrder>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.signature, {
          header: "zamówienie",
          size: 200,
          typographyProps: {
            fontSize: "16",
          },
        }),
        columnHelper.text(row => row.externalId, {
          header: "numer zewnętrzny",
          size: 200,
          typographyProps: {
            fontSize: "14",
            fontWeight: "600",
          },
        }),
        columnHelper.accessor(row => row, {
          id: "confirmOrder",
          header: " ",
          size: 200,
          cell: info => {
            const order = info.getValue();
            return <ConfirmOrder order={order} unloading={unloading} />;
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};
